<template>
  <div id="section-to-print">
    <h3 class="text-center" v-if="!tripData">Pleas go back and click print again. click here to go <router-link to="/gexpense">Back</router-link> </h3>
    <div v-else>
      <trip-view :tripData="tripData" v-if="tripData"></trip-view>
    </div>

  </div>
</template>
<script>
  import TripView from '../components/TripView';
  export default {
    data(){
      return {
        tripId: this.$route.params.id,
        tripData:[],
      }
    },
    watch:{
      '$route'(to, from){
        this.tripId = to.params.id;
        this.update();
      },
    },
    methods:{
      update(){
        this.axios.get('trip/'+this.tripId)
          .then(res => {
            this.tripData = res.data;
          })
      },

    },
    computed:{
      reveresed(){
        return this.data.expenses.reverse();
      }
    },
    created(){
      this.update();
    },
    mounted(){

    },
    components: {
      'trip-view':TripView
    }
  }
</script>
<style scoped>
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th, td {
    text-align: center;
  }
  @media print {
    h2 {
      color: black;
    }
  }
</style>
